import {Box} from "@mui/system";
import * as React from "react";
import {FC} from "react";
import Copyright from "../../quiz/copyright";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Socialicons from "../../quiz/socialicons";

export const PrintFooterAstrodating = () => {
    return(
            <Box sx={{
                width: '100%',
                minHeight: '50px',
                padding: '15px',
                zIndex: '1',
                marginTop: '50px',
                //background: 'linear-gradient(to bottom,  #1976d2 1%,#000000 98%)',
                backgroundColor: '#02D2D2',
                //background: 'linear-gradient(to bottom,  #02D2D2 0%,#008082 100%)',
                background: 'linear-gradient(to right,  #05b5cd 0%,#02d2d2 100%)',
                borderTop: '1px solid #282323',
                "-webkit-box-shadow": '0px 0px 8px 5px rgba(34, 60, 80, 0.2)',
                "-moz-box-shadow": '0px 0px 8px 5px rgba(34, 60, 80, 0.2)',
                boxShadow: '0px 0px 8px 5px rgba(34, 60, 80, 0.2)',
            }}>
                <Box sx={{
                    maxWidth: '900px',
                    width: '100%',
                    margin: '0 auto 0 auto'
                }}>
                    {Socialicons(true)}
                    <Typography variant="body2" color="text.secondary" align="center" style={{
                        margin: '10px auto 10px auto',
                        padding: '10px 0 0 0',
                        color: '#282323'
                    }}>
                        Copyright &copy;&nbsp;<u>AstroDating</u> 2015&nbsp;&mdash;&nbsp;{new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
    );
}