import Typography from "@mui/material/Typography";
import * as React from "react";
import {Data} from "../../../types/data";
import regionCodes from "../../forAll/regions";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {purple} from "@mui/material/colors";
import {CardActionArea, Grid} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

export const printCompatible = (compatible: number | string | null) => {
    if(typeof(compatible) === 'number' && compatible >= 0) {
        if(compatible  <= 30){ // low
            return <Typography className='person_compatible low' component="div">{compatible}%</Typography>
        }else {
            if (compatible < 75) { // middle
                return <Typography className='person_compatible mid' component="div">{compatible}%</Typography>
            } else { // high
                return <Typography className='person_compatible gold' component="div">{compatible}%</Typography>
            }
        }

    }
    return null;
}
export const getImagesSrc = (elem: Data) => {
    let src = '/images/noImg.jpg';
    if(elem.images !== 'hidden') {
        if(elem.images.split(',')[0]) {
            src = `/upload/${elem.images.split(',')[0]}`
        }
    }else{
        switch(elem.gender){
            case 'М':
                src = '/images/hide-man-photo.jpg';
                break;
            case 'Ж':
                src = '/images/hide-woman-photo.jpg';
                break;
        }
    }
    return src;
}


export const printRegionLabel = (searchId: string) => {
    if(searchId && searchId !== 'hidden') {
        regionCodes.forEach((code) => {
            if (searchId == code['value']) {
                return <Typography className='person_region' component="div">{code['label']}</Typography>
            }
        })
    }
    return null;
}

export const printLock = (vip: string | number, lock: boolean | undefined, hidebtn: boolean | undefined) => {
    if(!hidebtn) {
        if (vip !== '1' && (typeof (lock) === 'boolean')) {
            if (lock) {
                return (
                    <Typography gutterBottom variant="h6" component="div" sx={{
                        position: 'absolute', bottom: '3px', right: '3px', marginBottom: 0,
                        bgcolor: '#1e7fbb', padding: '7px 8px 0 8px', borderRadius: '50%'
                    }}>
                        <LockIcon sx={{color: '#fff'}}/>
                    </Typography>
                )
            } else {
                return (
                    <Typography gutterBottom variant="h6" component="div" sx={{
                        position: 'absolute', bottom: '3px', right: '3px', marginBottom: 0,
                        bgcolor: '#fff', padding: '7px 8px 0 8px', borderRadius: '50%'
                    }}>
                        <LockOpenIcon sx={{color: '#1e7fbb'}}/>
                    </Typography>
                )
            }
        }
    }
    return null;
}

export const printLeftBottom = (user_OutNum: string | number, id:string) => {
    if(user_OutNum == 'hidden'){
        if(id){
            return (
                <Typography gutterBottom variant="h6" component="div" sx={{
                    position: 'absolute', bottom: '3px', left: '3px', bgcolor: purple[50],
                    borderRadius: '8px', padding: '3px', marginBottom: 0,
                }}>
                    id{id}
                </Typography>
            )
        }else {
            return null;
        }
    }else {
        return (
            <Typography gutterBottom variant="h6" component="div" sx={{
                position: 'absolute', bottom: '3px', left: '3px', bgcolor: purple[50],
                borderRadius: '8px', padding: '3px', marginBottom: 0,
            }}>
                {user_OutNum}
            </Typography>
        )
    }
}
