import React from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// https://react-popup.elazizi.com/react-modal

const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" style={{
            borderTop: '1px solid #c4c4c4',
            maxWidth: '260px',
            margin: '10px auto 10px auto',
            padding: '10px 0 0 0'
        }}>
            Copyright &copy;&nbsp;
            <Link style={{whiteSpace: 'nowrap'}} color="inherit" href="https://svat-astrolog.com/">
                AstroDating
            </Link>{' '}2015&nbsp;&mdash;&nbsp;{new Date().getFullYear()}{'.'}
        </Typography>
    );
}

export default Copyright