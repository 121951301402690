import React, {useEffect} from 'react';
import {
    BrowserRouter, Link,
    Route,
    Routes
} from "react-router-dom";
//import Admin from './components/admin/admin';
//import AdminArchive from './components/adminPageCopy/archive';

import AdminInProcess from './components/admin/adminPageCopy/in_process'
import AdminSearchActive from './components/admin/adminPageCopy/search_active'
import AdminSearchPassive from './components/admin/adminPageCopy/search_passive'
import AdminSearchAll from './components/admin/adminPageCopy/search_all'
import AdminFavorites from './components/admin/adminPageCopy/favorites'
import AdminArchive from './components/admin/adminPageCopy/archive'
import AdminKorzina from './components/admin/adminPageCopy/korzina'
//import AdminTable from './components/admin/adminPageCopy/table' // -- old
import AdminClientsExcel from './components/admin/adminPageCopy/clients_excel'
import AdminClientsVk from './components/admin/adminPageCopy/clients_vk'
import AdminLineOne from './components/admin/adminPageCopy/lineone'

import Config from './components/admin/config';
import Snippets from './components/admin/snippets';
import ClippedDrawer from './components/admin/constituents/sidebar';
import Import from './components/admin/import';
import Lines from './components/admin/lines';
import Line3 from './components/admin/line3';
import Line3cfg from './components/admin/line3cfg';
import Line4 from './components/admin/line4';
import Line5 from './components/admin/line5';
import Actionlog from './components/admin/actionlog';
import Moderation from './components/admin/moderation';
import ModerationAdmin from './components/admin/moderation_admin';
import Profile from './components/admin/profile';
import ProfileAdd from './components/admin/profileAdd';
// import BaseAdminers from './components/basePage/baseAdminers';
import Cabinet from './components/cabinet/cabinet';
import CabinetHeader from './components/cabinet/cabinetHeader';
import Favorites from './components/cabinet/favorites';
import UserProfile from './components/cabinet/profile';
// import Header from './components/forAll/header';
import Login from './components/login';
import Recovery from './components/recovery';
import MainPage from './components/mainPage';
// import Quiz from './components/quiz';
import { getCookie } from './functions/Cookie';
// import { useAppSelector } from './hooks/hooks';
import useWhoIs from './hooks/useWhoIs';
import NotFound from './routes/NotFound';
import TicketAdd from './components/admin/tickets';
import Information from "./components/cabinet/info";
import Tariff from "./components/admin/tariff";
import { Navigate } from "react-router-dom";

document.title = "\"Счастье уже рядом!\" - Сват-Астролог";

function App() {

  useEffect(() => {
    document.title = "\"Счастье уже рядом!\" - Сват-Астролог";
  }, []);
  // const { easyRole } = useAppSelector(state => state.main)
  const  {whoIs}  = useWhoIs();
  //console.log(whoIs);
  const role = getCookie('role');
  return (
    <>
      <BrowserRouter >
        <Routes >
          <Route path='/' element={<MainPage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/activation/:token' element={<Login />} />
          <Route path='/recovery' element={<Recovery />} />


          {/* This page give access for all cards and some pages from admin panel */}
          {/* <Route path='/base' element={<CabinetHeader />}>
            <Route path='' element={<BaseAdminers />}/>
            <Route path='/admin' element={<BaseAdminers />} />
            <Route path='/users' element={<BaseAdminers />} />
          </Route> */}


          {(role === 'ok_admin' || whoIs === 'admin') ?

            <Route path='/admin' element={<ClippedDrawer />}>
                <Route path='moderation'           element={<Moderation />} />
                <Route path='moderation_admin'     element={<ModerationAdmin />} />
                <Route path='in_process'           element={<AdminInProcess />} />
                <Route path='search_active'        element={<AdminSearchActive />} />
                <Route path='search_passive'       element={<AdminSearchPassive />} />
                <Route path='search_all'           element={<AdminSearchAll />} />
                <Route path='favorites'            element={<AdminFavorites />} />
                <Route path='archive'              element={<AdminArchive />} />
                <Route path='korzina'              element={<AdminKorzina />} />
                {/*<Route path='table'                element={<AdminTable />} />*/}
                <Route path='clients_excel'        element={<AdminClientsExcel />} />
                <Route path='clients_vk'           element={<AdminClientsVk />} />
                <Route path='lineone'              element={<AdminLineOne />} />
                <Route path='linetwo'              element={<Lines />} />
                <Route path='linethree'            element={<Line3 />} />
                <Route path='linethreecfg'         element={<Line3cfg />} />
                <Route path='linefour'             element={<Line4 />} />
                <Route path='linefive'             element={<Line5 />} />
                <Route path='import'               element={<Import />} />
                <Route path='profile-add'          element={<ProfileAdd />} />
                <Route path='profile/:user_id'     element={<Profile />} />
                <Route path='config'               element={<Config />} />
                <Route path='config/:type'         element={<Config />} />
                <Route path='config/:type/:target' element={<Snippets />} />
                <Route path='config/tariff'        element={<Tariff />} />
                <Route path='tickets'              element={<TicketAdd />} />
                <Route path='clients_list'         element={<CabinetHeader />}>
                    <Route path='' element={<Cabinet />} />
                    <Route path='favorites' element={<Favorites />} />
                </Route>
                <Route path='action_log'           element={<Actionlog />} />
            </Route>

          : null}

          {((role !== 'ok_admin' && role !== 'admin') || (whoIs === 'user')) ?
            <Route path='/cabinet' element={<CabinetHeader />}>
                <Route path='' element={<Cabinet />} />
                <Route path='favorites' element={<Favorites />} />
                <Route path='info' element={<Information />} />
                <Route path='profile' element={<UserProfile />} />
            </Route>
          : null}
          <Route path='*' element={<NotFound />}/>
          {/*<Route path='*' element={<Navigate to="/notfound" replace />} />*/}

        </Routes>
        {/* {Logout ? <Redirect to="/" /> : null} */}
      </BrowserRouter>
    </>
  );
}

export default App;
