import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import * as React from 'react';
import Quiz from "./quiz";
import ScrollDialog from "./quiz/Dialog";
import Avatar from '@mui/material/Avatar';
import Copyright from "./quiz/copyright";
import Socialicons from "./quiz/socialicons";

const MainPage: FC = () => {

    const devmode = (window.location.hostname == 'localhost' || window.location.hostname == 'dev.svat-astrolog.com');
    //let tokenActivation = new URLSearchParams(this.props.location.search).get("__firebase_request_key")
    return(
        <>
            <Box sx={{margin: '0 auto 0 auto', width: '100%', maxWidth: '560px'}}>
                <Box sx={{margin: '0 8px 0 8px'}}>
                    <Typography component="h1" sx={{
                        '& > p': {
                            margin: '10px 0',
                            fontSize: '18px',
                            lineHeight: '18px'
                        }
                    }}>
                        <Typography component='div' style={{
                            textAlign: 'center',
                            lineHeight: '24px'
                        }}>
                            <p style={{color: '#222', fontSize: '16px'}}>Эксклюзивный астропсихолог,<br />
                            Сват-Астролог Вячеслав Горелов<br />
                            "Cчастье уже рядом!"</p>
                            <Avatar sx={{
                                m: 2,
                                bgcolor: 'white',
                                margin: '20px auto',
                                border: (devmode ? 1 : 0),
                                width: 128,
                                height: 128  }} src={devmode ? '/images/code.jpg' : '/images/avatar.jpg'} />
                            {/*<p style={{fontSize: '21px', fontWeight: '500'}}>Анкета для&nbsp;создания счастливой личной&nbsp;жизни!</p>*/}
                            <p style={{fontSize: '21px', fontWeight: '500', color: '#222', margin: '20px 0 20px 0'}}>Анкета для создания счастливой личной жизни на&nbsp;сервисе по&nbsp;поиску и&nbsp;подбору совместимого спутника&nbsp;жизни&nbsp;&mdash; AstroDating!</p>
                            {/*<p style={{ color: '#b00000' }}>Перед заполнением анкеты, рекомендую ознакомиться с&nbsp;<a target={'_blank'} rel={'nofollow noreferrer'} href={'https://vk.com/wall2782679_3073'} style={{color: '#1976d2',}}>отзывами</a>.</p>*/}
                        </Typography>
                    </Typography>
                    <Box sx={{ textAlign: 'center'}}>
                        <ScrollDialog mainPage>
                            <Quiz />
                        </ScrollDialog>
                        {/* <p>
                            <Button variant="outlined">
                                <Link to={"/base"}>Посмотреть доступные анкеты</Link>
                            </Button>
                        </p> */}
                        <Typography component='div' style={{
                            margin: "20px 0 30px 0"
                        }}>
                            <Button variant="outlined">
                                <Link to={"/login"} style={{
                                    color: 'rgb(25, 118, 210)',
                                }}>Личный кабинет</Link>
                            </Button>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{
                            // margin: '38px 0 0 0',
                            lineHeight: '20px',
                            fontSize: '0.8em',
                            color: 'rgba(0, 0, 0, 0.6)',
                        }}>
                            <p>Официальный сайт: <a rel='noreferrer' target="_blank" href='https://svat-astrolog.ru/?page_id=143' style={{
                                /*color: '#b00000'*/
                                color: '#537df6'
                            }}>svat-astrolog.ru</a></p>
                        </Typography>
                        {Socialicons(false)}
                        <Copyright />
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default MainPage